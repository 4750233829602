@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700,900|Roboto:400,500,700');

*, ::after, ::before
{
	box-sizing: border-box;
}

html,body {
    margin:0;
    padding:0;
    height:100%; 
    font-family: "Roboto", sans-serif;
    background:white;
    font-size:18px;
}

h1,h2,h3{
	font-family:"Montserrat", sans-serif !important;
	font-weight:700;
	line-height: 1.2;;
}
h2 
{
	font-size:2rem;
}
h4, h5 
{
	font-weight:500;
	margin:0;
}
h6 
{
	margin:0;
}
.container
{
	min-height:100%;
	position:relative;
}
.hide, .hidden
{
	 display:none !important;
}
.lng
{
	display:none;
}
.lng_en
{
	display:inherit;
}

.slogan
{
	background:#222;
	color:white;
	display:inline-block;	
	font-style:italic;
	font-size:30px;
	padding:15px 10px;
	margin:0;
}
.slogan b
{
	
}
a{text-decoration:none;}
.text-center 
{
	text-align: center !important;
}

/* ---- navbar OK -------------- */
nav 
{
	color:white;
	fill:white;
	display:Flex;
	position:fixed;
	width:100%;
	z-index:10;
	transition: all .25s;
	align-items: center;
	height:70px;
}
nav.dark 
{
	color:black;
	fill:black;
	background:white;
}
.navbar 
{
	display:flex;
	align-items: end;
	padding:15px 50px 10px 50px;
	width:100%;
	flex-direction: row-reverse;
}
.nav_brand 
{
	display:none;
	font-size: 28px;
    min-width: 300px;
}
nav.dark .nav_brand
{
	display:block;
}
.nav_menu 
{
	width:100%;
}

.main_menu 
{
	display:flex;
	justify-content: end;
	gap:20px;
	list-style:none;
	margin:5px 0 0 0;
}
nav a 
{
	color:inherit;
}
nav a:hover 
{
	color:lightgray;
	fill:lightgray;
}
nav.dark a:hover 
{
	color:gray;
	fill:gray;
}

.main_menu a 
{
	font-size:17px;
}
.social_menu 
{
	display:flex;
	gap:15px;
	justify-content: end;
	align-items: baseline;
}
.social_menu svg 
{
	width:17px;
}

.header_menu
{
	width:0;
	justify-content:flex-end;
	transition:width 0.5s;
	overflow:hidden;
}
.nav_white .header_menu
{
	width:100%;
}
.nav_white .nav_btn_folder
{
	display:none !important;
}

.nav_menu 
{
	opacity:0;
	transition:all 0.25s
}
/* HAMB */
.hamb{
	cursor: pointer;
	padding: 0;
	display:block;
	width:30px;
	height:20px;
	margin-bottom:5px;
	margin-left:20px;
  }
  
  .hamb-line {
	background: white;
	display: block;
	height: 4px;
	position: relative;
	width: 30px;
	top:9px;
  } 
  
  .hamb-line::before,
  .hamb-line::after{
	background: white;
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	transition: all .2s ease-out;
	width: 100%;
  }
  .hamb-line::before{
	top: 9px;
  }
  .hamb-line::after{
	top: -9px;
  }
  .side-menu:checked ~ .nav_menu
  {
	opacity:1;
  }
  .side-menu:checked ~ .hamb .hamb-line {
	background: transparent;
  }
  .side-menu:checked ~ .hamb .hamb-line::before {
	transform: rotate(-45deg);
	top:0;
  }
  .side-menu:checked ~ .hamb .hamb-line::after {
	transform: rotate(45deg);
	top:0;
  }

.side-menu
{
	display:none;
}

nav.dark .hamb-line,
nav.dark .hamb-line::before,
nav.dark .hamb-line::after{
	background:black;
}

nav.open .hamb
{
	display:none;
}
nav.open .nav_menu 
{
	opacity:1;
}

/* ---- header -------------- */
.header_text_wrapper
{
	position:absolute;
	width:100%;
	height:100vh;
	bottom:40px;
	left:0;
}
.header
{
	position:relative;
	left:0;
	top:0;	
	background-size: cover;
	background-position:center 35%;
	background-repeat: no-repeat;
	width:100%;
	height:100vh;
	display:flex;
	justify-content:center;
	align-items: center !important;
	padding-left: 50px;
	background-color: black;
	background-image:url("../img/francesca_bria_header.jpg");
}
.header_title
{
	position:relative;
}

.header_dark_gradient
{
	opacity: 0;
	transition:opacity 1s;	
}
.header_dark_gradient:before
{
	display:none;
	content:"";
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
   background: rgba(0,0,0,0.3);
   background: -moz-linear-gradient(left, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%);
   background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,0,0.1)), color-stop(50%, rgba(0,0,0,0.6)), color-stop(100%, rgba(0,0,0,0)));
   background: -webkit-linear-gradient(left, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%);
   background: -o-linear-gradient(left, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%);
   background: -ms-linear-gradient(left, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%);
   abackground: linear-gradient(to right, rgba(200,0,0,0.7) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%);
   background: linear-gradient(to right, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );	
	z-index:100;
}

.header_video
{
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index:100;
}
.header:after
{
	display:none;
	content:"";
	position:absolute;
	left:0;
	bottom:0;
	width:70vw;
	height:50%;
	border-bottom:110px solid rgba(255,255,255,1); /*rgba(0,0,0,0.5);*/
	border-right:45vw  solid transparent;
	z-index:200;
}
.header_row
{
	color:black;
	position:relative;
	width:100%;
	align-self:flex-end;
	margin-bottom:40px; /*60px;*/
	transition: margin-bottom 0.75s;
}
.header_margin
{
	margin-bottom:5px !important; 
	transition: margin-bottom 0.75s;
}
.header_text
{
	color:white;
	font-size:68px; 
	font-weight:700;
	line-height:1;
	padding:0;
	margin-bottom:5px;
}
.header_subtext 
{
	display:none;
	color:inherit;
	background:transparent;
	padding:0 5px 5px 0;
	font-size:26px;
	font-weight:normal;
	margin-top:0;
	text-transform:none;
	line-height:1.1;
}
.title_section
{
	margin-bottom:5px;
	font-weight:normal;
	font-size:24px;
	display:inline-block;
	border-bottom:4px solid #666;
	padding-bottom:5px;
}
.subtitle_section
{
	display:none;
	border-top:1px dotted black;
	adisplay:inline-block;
	padding-top:0px;
	margin:0;
	font-weight:normal;
	font-size:17px;
}
.header_section
{
	text-align:left;
	padding:30px 30px 30px 50px;
}
.option_section
{
	padding:0 30px;
	color:inherit;
}
.link_under
{
	text-decoration:underline;
}
.link_under:hover
{
	color:#6969ff !important;	
}

.link
{
	color:inherit;	
}
.link:hover
{
	color:inherit;
}


/* --- About me  ----- */

.about_text
{
	padding:0 40vw 0 50px;
	line-height:1.6;
	font-size:18px;
	position:relative;
	margin-top:40px;
	transition: margin-top 0.75s;
	position:relative;
	z-index:3;
}
.about_margin
{
	margin-top:10px;
	transition: margin-top 0.75s;
}
.about_section
{
	display:none;
	background:#f3f3f3;
	adisplay:flex;
	max-width:70%;
	margin:60px auto 50px auto;
	apadding:0 0 0 35px;
}
.about_section .about_col_photo
{
	flex:6;
	padding-top:20px;
	padding-bottom:20px;
	padding-right:50px;
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center bottom;
	/*background-image:url("../img/bio_francesca.jpg");*/
}
.about_section .about_col_photo .about_img
{
	adisplay:none;
	position:relative;
	width:100%;
	height:100%;
	abackground-size:cover;
	background-repeat:no-repeat;
	background-position:right bottom;
	/*background:url("../img/bio_francesca.jpg");*/
}
.about_section .about_col_bio
{
	padding:40px 70px 40px 70px;
	flex:3;
	font-size:18px;
}
.about_section .about_col_bio p
{
	line-height:1.1;
	font-size:18px;
}


/*------ About page -----*/
.content_wrapper
{
	padding-top:60px;
}
#about_page
{
	padding:0 50px;
	margin-top:50px;
	margin-bottom:100px;
}
.about_row
{
	display:flex;
}
.about_pic
{
	flex:2;
}
.about_pic img 
{
	width:100%;
	min-height:800px;
	object-fit: cover;
}
.about_bio
{
	flex:2;
	line-height:1.2;
	font-size:17px;
	padding-left:40px;
}
.about_bio h2
{
	margin:0;
}
.about_bio h5
{
	font-size:19px;
	margin:0 0 20px 0;
	color:#666;
}
.about_social
{
	display:block;
	margin-bottom:5px;
}
.about_social svg
{
	width:20px;
	fill:#555;
	margin-right:5px;
}
/* --- Talks ------ */
.talks_section
{
	background:white;
	padding-top:0px;
	acolor:white;
}
.talks_section .subtitle_section
{
	border-color:white;
}
.video_row
{
	display:flex;
}
.video_list_wrapper
{
	flex:2;
	padding:30px 0 30px 0;
}

.video_list_wrapper .link_go_projects
{
	margin-top:20px;
	display:inline-block;
}
.video_item 
{
	color:inherit;
	margin-bottom:20px;
	display:block;
	padding-right:40px;
}

.talk_date
{
	font-size:14px;
	margin-bottom:4px;
	color:gray;
}
.talk_title
{
	margin-bottom:6px;
	font-size:17px;
}

.talk_text 
{
	font-size:15px;
	font-weight: normal;
}
.video_wrapper
{
	flex:3;
	margin-top:40px;
	width: 100%;
	min-height:25vw;
    height: 0;
    padding-bottom: 33.75%; 
	position:sticky;
	top:75px;
	margin-bottom:40px;
}
#talks_grid .video_wrapper 
{
	position:relative;
	top:0;
	margin-bottom:15px;
}
.video_wrapper iframe
{
	width:100%;
	position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;	
	
}

/* --- Media ---- */
.media_section
{
	margin:-2 0 0 0 !important;
	padding:0 !important;
	background: #e9e9e9;;
}
.media_head
{
	abackground:#1d7c89;
	color:black;
	atext-align:left;
	apadding:50px 50px 20px 50px;
}
.media_head .title_section
{
	amargin:0;
}
.media_head .subtitle_section
{
	margin:0;
	font-weight:normal;
	font-size:17px;
}


#media_grid
{
	display:flex;
	flex-wrap:wrap;
	text-align:left;
	margin-top:40px;
	margin-bottom:0px;
}

.media_item
{
	width:25%;
	padding:10px 20px 10px 0;
}
.media_wrapper
{
	height:100%;
	padding:15px 15px 15px 15px;
	font-size:14px;
	background:white;	
	display:flex;
	flex-wrap:wrap;
	border-bottom:4px solid #ddd;

	flex-direction:column;	/* v2 */
}
.media_image
{
	object-fit: contain;
    object-position: center;
    width: 50%;
    max-width: 50px;
    aspect-ratio: 1;
    margin-bottom: 1rem;
    position: relative;
}
.media_media 
{
	margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 14px;
	border-bottom:0.1rem solid gray;
	padding-bottom:5px;
	line-height:1;
	
}
.media_info
{
	padding:0;
	aflex:1; /* v2 */
}
.media_details
{
	width:100%;
	padding-top:10px;
	color:black;

	display:flex;
	flex-direction: column;
	gap:0.5rem;

}
.media_title
{
   font-size:16px;
   margin:0 0 8px 0;
}
.media_date
{
	font-size:14px;
	color:gray;
	line-height:1;
	margin-bottom:4px;
}
.media_text
{
	font-size:inherit;
	font-weight:normal;
	margin-bottom:0.5rem;
}
.media_link
{
	float:right;
}

/* ----- media page --------- */ 
#media_page
{
	margin-top:0px;
}
#media_page #media_grid
{
	margin-top:0;
	padding:40px 50px;
	background:#e9e9e9;
}
.media_section_title
{
	margin:60px 50px 10px 50px;
	font-size:20px;
	display:inline-block;
	border-left:10px solid #666;
	border-right:10px solid #666;
	padding:0 20px;
}
.media_section_title:first-child
{
	margin-top:0;
}

#media_list
{
	width:100%;
	display:flex;
	flex-wrap:wrap;
}
#media_lists 
{
	margin:40px 0 120px 0;
	padding:0 50px;
}
.media_list 
{
	
	display:flex;
	flex-wrap:wrap;
}
.media_subtitle 
{
	margin-top:50px;
	margin-bottom:20px;
}
.media_line
{
	width:25%;
}
.media_line .media_wrapper
{
	background:white;
	padding:0;
	margin-bottom:10px;
	border:0;
}
.media_line .media_media
{
	font-size:15px;
	font-weight:normal;
	margin-bottom:0;
	border-bottom:none;
}
.media_line .media_info
{
	background:#f6f6f6;
	padding:15px 15px 10px 15px;
	margin:10px 20px 10px 0;
}
.media_line .media_date
{
	font-size:13px;
	color:gray;
	margin-bottom:15px;
	font-weight:normal;
}							
.media_line .media_title
{
	display:inline-block;
	color:inherit;
	line-height:1.2;
	padding-bottom:2px !important;
	border-bottom:1px solid transparent;
	text-decoration:none;
	font-size:16px;
	font-weight:500;
}
.media_line .media_title:hover
{
	aborder-bottom:1px solid #aaa;
	text-decoration:underline;
}

.w100
{
	content:"";
	display:block;
	width:100%;
}

.w100 .media_media
{
	aborder-bottom:1px dotted #ddd;
	font-size:20px;
	font-weight:normal;
	color:#666;
	margin-top:20px;
	margin-bottom:0px;
}

.w100 .media_info
{
	border:0;
	background:transparent;
	margin-bottom:0;
	margin-left:0;
	padding-bottom:0;
}
/* ----- talks page --------- */ 
#talks_grid
{
	display:flex;
	flex-wrap:wrap;
	margin:0px 40px 100px 40px;
}
.talk_item
{
	width:50%;
}
.talk_wrapper
{
	padding:10px;
	font-size:14px;
}
.talk_wrapper .video_wrapper
{
	margin-top:20px;
}
.talk_type_audio
{
	display:block;
	width:100%;
	aalign-self:bottom;
	margin-top:23.7vw;
}
/*
.talk_title
{
	margin:0 0 5px 0;
	font-size:17px;
	font-weight:bold;
}
.talk_text
{
	font-size:15px;
}
.talk_date
{
	font-size:14px;
	color:#666;
	line-height:1;
	margin:14px 0 4px 0;
}
*/
/* ---- Foot ----------*/
footer
{
	background:rgba(0,0,0,.78);
	color:white;
	margin:0;
	width:100%;
	padding:40px 50px 40px 50px;
	font-size:15px;
	adisplay:flex;
	align-items: center;
	text-align:center;
}
.menu_footer a
{
	display:inline-block;
	border-right:1px solid white;
	margin:5px 0;
	padding:0 10px;
	line-height: 1;
	color:inherit;
}
footer a:hover
{
	text-decoration:none;
	color:#ddd;
}
footer a:hover svg
{
	fill:#ddd;
}

.menu_footer a:last-child
{
	border:0!important;
	padding-right:0 !important;
}
footer span:first-child
{
	padding-left:0 !important;
}

.social_footer
{
	display:flex;
	align-items:flex-end;
	justify-content: center;
	margin-top:20px;
}
.social_footer a
{
	padding:0 15px;
}
.social_footer svg
{
	fill:white;
	width:24px;
}
/* --- My Work ---- */
.work_section
{
	background:#efefef;
	margin-top:50px;
	apadding-top:20px;
}


/* ---- projects home  ---------------*/

.projects_section
{
	text-align:center;
	margin-bottom:20px;
	background:#e0e0e0;
	padding:0;
	font-size:16px;
}
.projects_section_page
{
	min-height:100vh;
	display:block;
	position:relative;
	padding:50px 0 150px 0;
	margin:0;
	background:white;
}
.projects_section p
{
	font-size:1.2em;
	acolor:gray;
	atext-transform:uppercase;
	text-align:left;
	padding:10px 50px;
}

.project_tag_container
{
	text-align:left;
	padding:10px 50px;
	margin:0 0 50px 0;
}
.project_tag
{
	border:1px solid black;
	padding: 5px 10px;
	text-transform: uppercase;
	font-size:0.9em;
	background:transparent;
	color:black;
	text-decoration: none;
	margin:0 5px 0 0;
	atransition: background 0.5s;
}
.project_tag:hover
{
	text-decoration: none;
	color:inherit;
	atransition: background 0.5s;
}
.project_tag.selected
{
	background:black;
	color:white;
	transition: background 0.5s;
	transition: color 0.2s;
}

.link_go_projects
{
	color:black;
	padding:10px;
	border-top:1px solid black;
	font-weight:normal;
	adisplay:block;	
}

.link_go_projects:hover
{
	color:#666;
	fill:	#666;
	acolor:inherit;	
	text-decoration: none;
	border-color:#666;
	abackground:#f6f6f6;
}

.projects_section .btn_projects
{
	margin:40px 0 20px 0;
	background:transparent !important;
	border:1px solid black !important;
	border-radius:0 !important;
	outline:none;
}
.projects_section .btn_projects:focus
{
	outline:none;
	box-shadow: none;
}
.projects_section .btn_projects:hover
{
	background:black !important;
	color:white;
	border:1px solid black !important;
	border-radius:0 !important;
}

.page_head_section
{
	margin:0 50px 60px 50px;
	text-align:left;
}
.page_title_section
{
	margin:0;
	padding:10px 0 10px 0;
	line-height:1;
	font-size:38px;
}

.page_subtitle_section
{
	margin:0;
	font-weight:normal;
	font-size:17px;
}


.page_title_grid
{
	display:block;
	text-align:left;
	margin:0 50px 0 50px;
	Amargin-bottom:40px;
	font-size:30px;
	padding:10px 0 10px 0;
	line-height:1;
	font-size:28px;
}

/* ---- projects page  ---------------*/

.projects_section_page .header_section
{
	text-align:left !important;
	margin-top:0 !important;
	margin-bottom:30px !important;
	padding-left:50px !important;
	background:#f6f6f6;
}
.projects_section_page #projects_grid
{
	text-align:left;
}
.projects_section_page .option_section
{
	padding-left:0 !important;
}
.projects_section_page .title_section
{
	display:block;
	text-align:left;
	margin:0 50px 0 50px;
	margin-bottom:40px;
	font-size:30px;
	border-top:1px dotted gray;
	border-bottom:1px dotted gray;
	aborder-left:10px solid #555;
	aborder-right:10px solid #555;
	padding:10px 20px 10px 0;
	line-height:1;
	font-size:30px;
	abackground:#f6f6f6;
}
.works_list
{
	padding:0px 30px 80px 30px;
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	text-align:left;
}
.works_list .work_item
{
	width:33%;
	display:flex;
	padding:20px;
	max-width:33%;
	flex-grow:1;
	
	flex-wrap:wrap;
	flex-direction:row;
	aborder:1px solid #ccc;
	amargin-bottom:20px;
}
.works_list .work_wrapper
{
	background:#f4f4f4;	
}
.works_list .work_content
{
	adisplay:flex;
	apadding-left:50px;
	margin-top:5px;
	padding:10px;
	aborder-bottom:10px solid #ccc;
}
.works_list .work_title
{
	flex:1;
	font-size:16px;
	display:inline-block;
	margin-bottom:0px;
	font-weight:bold;
	aborder-bottom:1px solid #ccc;
	color:inherit;
	text-decoration:underline;
}
.works_list .work_title:hover
{
	atext-decoration:none;
	aborder-color:black;
	color:gray;	
}
.works_list .work_desc
{
	flex:2;
	font-size:16px;
	margin-top:5px;
	padding:0;
	line-height:1.2;
	padding-bottom:20px;
}

.works_list .work_photo
{
	adisplay:none;
	flex:1;
	min-height:300px;
	height:25vw;
	aborder:1px dotted #ccc;
	background-size:cover !important;
	border:1px solid #e0e0e0;
}
.works_list .work_info
{
	flex:3;
	padding:0 20px 0px 00px;
	font-size:14px;
	aborder-bottom:1px dotted #aaa;
	aborder-top:1px dotted #aaa;
}

/* ---- home content OK -------------- */
.section_header
{
	text-align:left;
	padding:30px 30px 30px 50px;
}
.section_title svg
{
	width:14px;
	height:14px;
	fill:inherit;
}
.section_foot
{
	padding-bottom:80px;
}
.section_title
{	
	display:block;
	color:#333;
	font-weight:700;
	text-transform:none;
	font-size:19px;
	position:relative;
	line-height:1;
	margin:0;
}


/* ---- projects home & page ---------------*/
.projects_section
{
	text-align:center;
	margin-bottom:20px;
	background:#e0e0e0;
	padding:0;
	font-size:16px;
}
.projects_section_page
{
	min-height:100vh;
	display:block;
	position:relative;
	padding:0px 0 150px 0;
	margin:0;
	background:white;
}
.projects_section p
{
	font-size:1.2em;
	acolor:gray;
	atext-transform:uppercase;
	text-align:left;
	padding:10px 50px;
}


#btn_go_talks
{
	aborder-color:white !important;
	acolor:white !important;
	afill: white !important;
}
#btn_go_talks:hover
{
	abackground:#111 !important;	
	aborder-color:#d6d6d6 !important;
}

.projects_section .btn_projects
{
	margin:40px 0 20px 0;
	background:transparent !important;
	border:1px solid black !important;
	border-radius:0 !important;
	outline:none;
}
.projects_section .btn_projects:focus
{
	outline:none;
	box-shadow: none;
}
.projects_section .btn_projects:hover
{
	background:black !important;
	color:white;
	border:1px solid black !important;
	border-radius:0 !important;
}

/* ---- project items -------- */
.projects_home_grid
{
	margin-top:0px;
	padding:0px 30px 0px 30px;
	display:flex;
	flex-wrap:wrap;
}
.project_item
{
	padding:20px !important;
	width:25%;
	margin-bottom:20px;
}
#works .project_item 
{
	width:33.33%;
}
.project_image
{
	border:1px solid #ccc;	
	width:100%;
	aspect-ratio: 1;
	object-fit: cover;
	object-position: center top;
}
#works .project_image 
{
	aspect-ratio:1/1;
}
.project_info
{	
	padding:15px 0px 10px 0px;
}

.project_info .project_title
{
	font-size:16px;
	margin:0 0 5px 0;
	font-weight:bold;
	line-height:1.2;
	display:inline-block;	
	text-decoration:none;
	border-bottom:1px solid #ccc;
	color:inherit;
}
.project_info .project_title:hover
{
	text-decoration:none;
	border-color:black;	
}

.project_info .project_desc
{
	font-size:14px;
	color:#666;
	margin:0 0 3px 0;
}
#works .project_info .project_short 
{
	display:none;
}
#home .project_info .project_long
{
	display:none;
}

.project_info .project_date
{
	font-size:14px;
	color:#666;
	margin:0 0 3px 0;
}
.project_info .project_types
{
	width:100%;
}

.project_info .project_type
{
	margin-top:10px;
	font-size:14px;
	padding:5px 10px;
	background:#eee;
	border-radius:5px;
	display:none; /*inline-block*/;
}

.btn_more
{
	background:transparent !important;
	border:3px solid black !important;
	border-radius:0 !important;
	margin:20px 0;
	apadding:10px 20px;
	atext-transform: uppercase;
}

.btn_more:hover
{
	background:black !important;
	color:white;
	border:3px solid black !important;
	border-radius:0 !important;
}

/* ---- contact -------------- */
.body_flex
{
	display:flex;
	flex-direction:column;
}
/*.body_flex .navbar
{
	background:transparent !important;
}*/
#contact
{
	abackground:#eee;
	margin:0px 0px;
	flex-grow:1;
}
.contact_wrapper
{
	abackground:#fff;
	margin:25px 50px;
	padding:50px 100px 50px 100px;
	aborder-radius:10px;
}
#contact input, #contact textarea
{
	border:0;
	border-bottom:1px solid black;
	margin-bottom:15px;
	outline:none;
	border-radius: 0;
	padding:0 0 0 5px;
	transition: background 0.5s;
}
#contact input:focus, #contact textarea:focus
{
	border-color:orange;
	outline:none;
	box-shadow:none;
}

#contact button
{
	background:transparent;
	border:1px solid black;
	border-radius: 0;
	color:black;
	margin-top:20px;
}
#contact button:hover
{
	background:black;
	color:white;
}

#contact h2
{
	text-transform:uppercase;
}

.contact_info
{
	margin-bottom:30px;
	padding-right:50px;
}

.subscription_options
{
	margin-top:10px;
	margin-left:7px;
	padding-top:10px;
	padding-left:20px;
	border-left:1px dotted black;
}
.subscription_options.disabled .subscription_option
{
	color:#aaa;
}
.subscription_options.disabled .custom-control-input:checked~.custom-control-label::before
{
	background:#aaa;
}
.custom-control-input:checked~.custom-control-label::before
{
	background:black;
	border-color:inherit;
}
.custom-checkbox .custom-control-label::before
{
	border-radius:0;
}
.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before
{
	background:#aaa;
}
.contact_result
{
	display:none;
	margin-top:20px;
	margin-right:10px;
	padding:5px 10px;
	color:white;
	font-size:16px;
	border-radius:0;
}
#btn_contact
{
	box-shadow:none;
}
.sending div div
{
	abackground:white !important;
}


/* ----cookies -------------- */
.cookies_msg
{
	display:block;
	position:fixed;
	bottom:0;
	right:0;
	padding:30px 30px;
	background:orange;
	width:350px;
	color:white;
	z-index:255;
	opacity:1;
	transition:all 0.5s;
}
.cookies_msg.hide 
{
	opacity:0;
}
.cookies_row
{
	display:flex;
	align-items: center;
}
.cookies_msg p
{
	flex:1;
	margin-bottom:0;
	line-height:1.2;
}
.cookies_msg .cookies_accept
{
	display:inline-block;
	margin-top:20px;
	border:2px solid white;
	padding:5px 10px;
	text-decoration: none;
}
.cookies_msg .cookies_accept:hover
{
	color:black;
	background:white;
}
.cookies_msg a
{
	color:inherit;
	text-decoration: underline;
}


  
